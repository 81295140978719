@import 'src/styles/variables/colors';
@import 'src/styles/variables/mixins';

.root {
  position: relative;
  display: inline-flex;
}

.text {
  display: inline-flex;
  align-items: center;
  color: $secondary;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  user-select: none;

  &::before {
    content: '';
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border: 1px solid $gray-02;
    border-radius: 4px;
    background: $gray-01;
  }
}

.input[type='radio'] + .text::before {
  border-radius: 50%;
}

._align-top {
  align-items: flex-start;
}

._align-center {
  align-items: center;
}

._isError::before {
  border-color: $error;
}

.input {
  @include invisible-input;
}

.input:checked + .text::before {
  border-color: $accent-default;
  background-image: url('./check.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.input:checked + .text {
  color: $text;
}

.input:not(:disabled, :checked) + .text:hover::before {
  border-color: $accent-default;
}

.input[type='radio']:checked + .text::before {
  background-image: url('./check-radio.svg');
}
