@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  position: relative;
  padding-bottom: 40px;
}

._position-top {
  .btn_submit {
    position: absolute;
    bottom: -24px;
    left: calc((100% - 240px) / 2);

    @include breakpoint($sm) {
      position: relative;
      bottom: initial;
      left: initial;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}

._position-bottom {
  .btn_submit {
    display: flex;
    justify-content: center;
  }
}

.header {
  margin-bottom: 24px;
  color: $accent-default;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: center;

  @include breakpoint($sm) {
    margin-bottom: 40px;
    font-size: 27px;
    line-height: 32px;
    text-align: left;
  }
}

._template-main {
  .btn_submit {
    @include breakpoint($md) {
      margin-top: 64px;
    }
  }

  .header {
    display: none;
  }

  .form {
    @include breakpoint($md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    @include breakpoint($lg) {
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;
    }
  }
}

._template-1 {
  .form {
    @include breakpoint($sm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }
}

._template-2 {
  .form {
    @include breakpoint($sm) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }
  }
}

.field > div > label {
  margin-bottom: 16px;
  color: $secondary;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.element_groups {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include breakpoint($sm) {
    gap: 16px;
  }
}
