@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  position: relative;
}

._position-top {
  padding-bottom: 32px;

  .btn_submit {
    position: absolute;
    bottom: -24px;
    left: calc((100% - 240px) / 2);

    @include breakpoint($sm) {
      position: relative;
      bottom: initial;
      left: initial;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}

._position-center {
  .btn_submit {
    display: flex;
    justify-content: center;

    @include breakpoint($sm) {
      justify-content: flex-start;
    }
  }
}

._position-bottom {
  padding-bottom: 40px;

  .btn_submit {
    display: flex;
    justify-content: center;
  }
}

.header {
  margin-bottom: 24px;
  color: $accent-default;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: center;

  @include breakpoint($sm) {
    margin-bottom: 40px;
    font-size: 27px;
    line-height: 32px;
  }
}

.links {
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  @include breakpoint($md) {
    text-align: left;
  }

  a {
    display: contents;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    white-space: break-spaces;
  }
}

._variant-modal {
  padding-bottom: 0;

  .row {
    @include breakpoint($md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
    }
  }

  .description {
    p {
      color: $text;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;

      @include breakpoint($md) {
        font-size: 16px;
        line-height: 24px;
        text-align: left;
      }
    }
  }

  .btn_submit {
    @include breakpoint($sm) {
      justify-content: flex-start;
    }
  }
}

._variant-banner {
  .row {
    display: flex;
    flex-direction: column;
  }

  .links {
    color: rgba($text, 0.7);
    text-align: center;

    a {
      line-height: 18px;
    }
  }
}

.form {
  margin-top: 24px;

  @include breakpoint($md) {
    margin-top: 0;
  }
}

.btn {
  display: flex;
  justify-content: center;

  @include breakpoint($md) {
    justify-content: flex-start;
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.lds_ellipsis {
  position: relative;
  top: calc(50% - 80px);
  left: calc(50% - 40px);
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds_ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds_ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds_ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds_ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds_ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  color: $error;
}

.error_title {
  margin: 0 0 16px;
  color: $error;
  text-align: center;
}

.error_text {
  margin: 0 0 32px;
  text-align: center;
}
