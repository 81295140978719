@import 'src/styles/variables/colors';
@import 'src/styles/variables/effects';
@import 'src/styles/variables/mixins';
@import 'src/styles/variables/breakpoints';

.root {
  position: fixed;
  inset: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  @include transition(opacity);

  &._isClosed {
    opacity: 0;
  }

  &._isOpen {
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  inset: 0;
  bottom: unset;
  display: none;
  height: 200%;
  background-color: $black;
  opacity: 0.6;

  @include breakpoint($sm) {
    display: block;
  }
}

.container {
  position: relative;
  inset: initial;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: $white;

  @include breakpoint($sm) {
    overflow: hidden;
    width: 800px;
    height: auto;
    min-height: 200px;
    max-height: calc(100vh - 160px);
    padding: 24px 24px 56px 72px;
    border-radius: 32px;
  }
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  white-space: nowrap;

  @include breakpoint($sm) {
    padding-right: 48px;
    font-size: 32px;
    line-height: 40px;
  }
}

.subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @include breakpoint($sm) {
    margin-top: 4px;
    font-size: 20px;
    line-height: 32px;
  }
}

.content_wrapper {
  overflow-y: auto;

  // height: calc(100% - 40px - 32px);

  height: calc(100% - 40px);
  margin-top: 32px;

  @include breakpoint($sm) {
    height: 100%;
    max-height: calc(100vh - 160px - 24px - 56px);
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 32px;
    background-color: rgba($black, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border: 3px rgba($accent-default, 0.6) solid;
    border-radius: 60px;
    background-color: rgba($accent-default, 0.6);
  }
}

.content {
  @include breakpoint($sm) {
    padding-right: 42px;
  }
}

.btn_close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  color: $black;
  @include transition(color);

  @include breakpoint($sm) {
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: $accent-default;
  }
}
